<template>
  <div class="single-traffic-video-detail">
    <div class="common-module relative video-info-left">
      <div class="module-title flex justify-between items-center">
        <div class="flex items-center">
          <img
              src="../../../../../assets/images/admin/single/icon/icon_shujuliebiao.png"
              alt=""
          />
          <span class="title-text ml-3">视频监测</span>
        </div>
      </div>
      <div class="platform-scroll left-bottom">
        <el-collapse v-model="activeName">
          <el-collapse-item
            title="桥梁视频监控"
            name="桥梁视频监控"
          >
            <div
              v-for="(v, j) in videoList"
              :key="j"
              class="video-list-item ellipsis"
              :class="{ active: v.id == currentVideoId }"
              @click="handleShowVideo(v)"
            >
              {{ v.name }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="common-module relative video-info-right">
      <div class="video-view">
        <div
          class="video-color"
          :style="videoHeight"
          v-for="(item, index) in moreVideoListData"
          :key="index"
        >
          <div
            class="video-flex-item"
            v-for="(items, indexs) in item.videoflexitem"
            :key="indexs"
            v-bind:class="{
              active: lightVideoFlagA == index && lightVideoFlagB == indexs,
            }"
            @click="handleShowVideo(items)"
          >
            <div class="h5videowrapper h5container" v-if="items">
              <vue-aliplayer-v2
                ref="videoPlayer"
                :source="items.url"
                :options="options"
                @ready="handleReady(indexs)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="video-opt">
        <span
          data-v-6ccdac90=""
          type="button"
          data-row="1|1"
          class="btn"
          @click="changeVideonum('1', '1')"
          >
          <img v-if="nowStatus == 1" src="../../../../../assets/images/single/video/one_cur.png" alt="">
          <img v-else src="../../../../../assets/images/single/video/one.png" alt="">
        </span>
        <span
          data-v-6ccdac90=""
          type="button"
          data-row="2|2"
          class="btn"
          @click="changeVideonum('2', '2')"
          >
          <img v-if="nowStatus == 2" src="../../../../../assets/images/single/video/four_cur.png" alt="">
          <img v-else src="../../../../../assets/images/single/video/four.png" alt="">
        </span>
        <span
          data-v-6ccdac90=""
          type="button"
          data-row="3|3"
          class="btn"
          @click="changeVideonum('3', '3')"
          >
          <img v-if="nowStatus == 3" src="../../../../../assets/images/single/video/nine_cur.png" alt="">
          <img v-else src="../../../../../assets/images/single/video/nine.png" alt="">
        </span>
        <span
          data-v-6ccdac90=""
          type="button"
          data-row="4|4"
          class="btn"
          @click="changeVideonum('4', '4')"
          >
          <img v-if="nowStatus == 4" src="../../../../../assets/images/single/video/sixteen_cur.png" alt="">
          <img v-else src="../../../../../assets/images/single/video/sixteen.png" alt="">
        </span>
      </div>
    </div>
    <!-- 监控视频弹框 -->
    <el-dialog
        custom-class="video-info-dialog"
        :visible.sync="dialogVisible"
        width="70%"
        top="5vh"
        close-on-click-modal
        :before-close="beforeClose"
        append-to-body
    >
      <vue-aliplayer-v2
          ref="videoPlayer"
          :source="currentVideo.url"
          :options="options"
      />
    </el-dialog>
  </div>
</template>

<script>
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2,
  },
  data() {
    return {
      options: {
        height: "100%",
        isLive: true,
        format: "m3u8",
        autoplay: true,
        skinLayout: false
      },
      currentVideo: {},
      videoList: [],
      isFullscreen: false,
      activeName: 1,
      currentVideoId: "1-1",
      videoHeight: {
        height: "100%",
      },
      nowStatus: 1,
      lightVideoFlagA: 0,
      lightVideoFlagB: 0,
      moreVideoListData: [
        {
          videoflexitem: [],
        },
      ],
      dialogVisible: false,
    };
  },
  async created() {
    let videoList = [];
    // let baseUrl = localStorage.getItem('baseUrl');
    
    this.$http.get("/video/getVideoInfo").then(res => {
        let upStream = res.data.upStream;
        let downStream = res.data.downStream;
        // let upStream = this.$BRIDGE_VIDEO_LIST[baseUrl].upStream;
        // let downStream = this.$BRIDGE_VIDEO_LIST[baseUrl].downStream;
        for (let i = 0; i < upStream.length; i++) {
          videoList.push(upStream[i]);
        }
        for (let i = 0; i < downStream.length; i++) {
          videoList.push(downStream[i]);
        }
        this.videoList = videoList;
        if (this.videoList.length > 0){
          this.currentVideoId = this.videoList[0].id
        }
        this.addvideosgrid(1,1);
    })
  },
  methods: {
    handleShowVideo(v) {
      this.currentVideo = v;
      if(this.currentVideo.id) this.currentVideoId = this.currentVideo.id;
      this.dialogVisible = true;
    },
    beforeClose(done) {
      done();
    },
    handleReady(index) {
      this.$refs.videoPlayer[index].play();
    },
    //改变视频弹窗的数量
    changeVideonum(rows, cols) {
      let self = this;
      let rNum = Number(rows);
      let cNum = Number(cols);
      if (rNum === self.nowStatus) {
        return false;
      } else {
        self.addVideo(rNum, cNum); //增加
      }
      self.nowStatus = rNum;
    },
    //增加视频格子
    addVideo(rNum, cNum) {
      let self = this;
      let len = rNum - self.nowStatus;
      if (rNum == 2) {
        self.videoHeight.height = "50%";
        self.addvideosgrid(rNum, len);
      } else if (rNum == 3) {
        self.videoHeight.height = "33.3%";
        self.addvideosgrid(rNum, len);
      } else if (rNum == 4) {
        self.videoHeight.height = "25%";
        self.addvideosgrid(rNum, len);
      } else {
        self.videoHeight.height = "100%";
        self.addvideosgrid(rNum, len);
      }
    },

    addvideosgrid(rNum, len) {
      let self = this;
      self.moreVideoListData = [];
      let videoList = self.videoList;
      console.log('videoList',videoList);
      for (let i = 0; i < rNum; i++) {
        let videoflexitem = [];
        for (let j = 0; j < rNum; j++) {
          videoflexitem.push(videoList[i*rNum+j]);
        }
        self.moreVideoListData.push({
          videoflexitem: videoflexitem
        })
      }
    },
  },
};
</script>
<style scoped>
  .video-info-dialog{
    background: #fff !important;
  }
  .video-info-dialog /deep/ .el-dialog__body{
    padding: 30px 20px;
  }
</style>
